<div
    aria-labelledby="progress-bar-label"
    class="d-flex justify-content-between progress-bar-container mb-3"
    *otbShowInEnv="['default', 'justice', 'sh', 'education-check', 'finance', 'hmdj', 'hzd-internal', 'bb-jm']"
>
    <div class="progress-bar" role="progressbar" [attr.aria-valuenow]="currentStep" [attr.aria-valuemax]="totalSteps">
        <div
            class="progress-bar--inner"
            [style.width]="progress"
            [style.background-color]="getProgressBarColor()"
        ></div>
    </div>
    <span
        class="justify-content-end text-end"
        id="progress-bar-label"
        [attr.aria-label]="
            ('dynamische_frontend_texte.landing.attribute_text.fortschritt' | translate) +
            ' ' +
            currentStep +
            ' von ' +
            totalSteps
        "
    >
        <span [innerHTML]="'dynamische_frontend_texte.landing.html_content.fortschritt' | translate | safeStyle"></span>
        {{ currentStep }}/{{ totalSteps }}
    </span>
</div>
