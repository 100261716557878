import { Component, OnDestroy, OnInit } from '@angular/core';

import { ApiConfiguration } from '../../../api/api-configuration';
import { ApplicationSettingsService } from '../../../services/application-settings/application-settings.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'otb-bb-jm-logo',
    standalone: true,
    templateUrl: './bb-jm-logo.component.html',
    imports: [],
    styleUrl: './bb-jm-logo.component.scss'
})
export class BbJmLogoComponent implements OnInit, OnDestroy {
    rootUrl: string = '';
    showUploadedLogo: boolean | undefined;
    subs = new Subscription();

    constructor(
        private apiConfiguration: ApiConfiguration,
        private applicationSettingsService: ApplicationSettingsService
    ) {
        this.rootUrl = this.apiConfiguration.rootUrl;
    }

    ngOnInit() {
        this.subs.add(
            this.applicationSettingsService.showUploadedLogoSubject.subscribe((show: boolean) => {
                this.showUploadedLogo = show ?? false;
            })
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
