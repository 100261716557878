<div [formGroup]="form" class="form-group">
    <input
        [id]="fieldName"
        type="file"
        class="form-control-file file-input"
        [ngClass]="{ 'is-invalid': !isValid && (isDirty || isTouched) }"
        [attr.autocomplete]="formField.autocomplete !== '' ? formField.autocomplete : null"
        (change)="onFileSelected($event)"
        #fileUpload
    />
    <div class="file-upload row">
        <div class="col-8 justify-content-center align-self-center filename">
            {{ fileName || 'Bisher wurde keine Datei hochgeladen.' }}
            <!--todo: make translatable-->
        </div>
        <div class="col-4 text-right">
            <button
                type="button"
                class="btn btn-success btn-sm upload-btn"
                (click)="fileUpload.click()"
                btnPrimaryColorDirective
            >
                <span class="text-decoration-none mr-1 search-icon">
                    <fa-icon class="fa" [icon]="faFile"> </fa-icon>
                </span>
                Suchen
                <!--todo: make translatable-->
            </button>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-11">
            @if (uploadProgress) {
                <ngb-progressbar [max]="100" [value]="uploadProgress"></ngb-progressbar>
            }
        </div>
        <div class="col-1">
            @if (uploadProgress) {
                <fa-icon class="fa" [icon]="faCancel" (click)="cancelUpload()"></fa-icon>
            }
        </div>
    </div>
    <otb-form-error-message
        [show]="!isValid && (isDirty || isTouched) && errors?.['required']"
        [text]="'dynamische_frontend_validatoren.persönliche_daten.datei_upload_nicht_leer' | translate"
    >
    </otb-form-error-message>
    <otb-form-error-message
        [show]="!isValid && (isDirty || isTouched) && errors?.['maxSize'] !== undefined"
        [text]="
            'dynamische_frontend_validatoren.persönliche_daten.datei_upload_max_file_size'
                | translate
                    : {
                          name: errors?.['maxSize']?.file.name,
                          size: (errors?.['maxSize']?.file.size / 1024 / 1024 | number: '1.0-2'),
                          suffix: 'MB',
                          limit: (errors?.['maxSize']?.limit / 1024 / 1024 | number: '1.0-2')
                      }
        "
    >
    </otb-form-error-message>
    <otb-form-error-message
        [show]="!isValid && (isDirty || isTouched) && errors?.['emptyFile'] !== undefined"
        [text]="
            'dynamische_frontend_validatoren.persönliche_daten.file_upload_empty_file'
                | translate
                    : {
                          name: errors?.['maxSize']?.file.name
                      }
        "
    >
    </otb-form-error-message>
    <otb-form-error-message
        [show]="!isValid && (isDirty || isTouched) && errors?.['mimeTypes'] !== undefined"
        [text]="
            'dynamische_frontend_validatoren.persönliche_daten.datei_upload_ungültiger_mime_typ'
                | translate
                    : {
                          name: errors?.['mimeTypes']?.file.name,
                          types: errors?.['mimeTypes']?.permitted_values
                      }
        "
    >
    </otb-form-error-message>
</div>
