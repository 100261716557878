/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ContentPageResponse } from '../models/content-page-response';
import { getApiPublicContentPageList } from '../fn/pages/get-api-public-content-page-list';
import { GetApiPublicContentPageList$Params } from '../fn/pages/get-api-public-content-page-list';


/**
 * Pages
 */
@Injectable({ providedIn: 'root' })
export class PagesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getApiPublicContentPageList()` */
  static readonly GetApiPublicContentPageListPath = '/api/public/{_locale}/content-pages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiPublicContentPageList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPublicContentPageList$Response(params: GetApiPublicContentPageList$Params, context?: HttpContext): Observable<StrictHttpResponse<ContentPageResponse>> {
    return getApiPublicContentPageList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApiPublicContentPageList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPublicContentPageList(params: GetApiPublicContentPageList$Params, context?: HttpContext): Observable<ContentPageResponse> {
    return this.getApiPublicContentPageList$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentPageResponse>): ContentPageResponse => r.body)
    );
  }

}
