@if (servicePreselectionWarning || agencyPreselectionWarning) {
    <div class="animated fadeIn">
        @if (servicePreselectionWarning) {
            <div class="row">
                <div class="col-lg-12">
                    <div class="alert-box">
                        <div
                            class="alert alert-warning"
                            role="alert"
                            [innerHTML]="
                                'dynamische_frontend_texte.landing.html_content.unbekannte_leistung'
                                    | translate
                                    | safeStyle
                            "
                        ></div>
                    </div>
                </div>
            </div>
        }
        @if (agencyPreselectionWarning) {
            <div class="row">
                <div class="col-lg-12">
                    <div class="alert-box">
                        <div
                            class="alert alert-warning"
                            role="alert"
                            [innerHTML]="
                                'dynamische_frontend_texte.landing.html_content.unbekannte_dienststelle'
                                    | translate
                                    | safeStyle
                            "
                        ></div>
                    </div>
                </div>
            </div>
        }
    </div>
}
