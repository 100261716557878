<div class="row">
    <otb-inner-breadcrumb
        *otbShowInEnv="['default', 'sh', 'education-check', 'finance', 'justice', 'hmdj', 'bb-jm', 'hzd-internal']"
    ></otb-inner-breadcrumb>
    <otb-progress-bar></otb-progress-bar>
</div>

<div class="row">
    <div class="col-12">
        <h1
            *otbShowInEnv="['hzd-internal']"
            [innerHTML]="
                'dynamische_frontend_texte.persoenliche_daten.html_content.einfuehrung' | translate | safeStyle
            "
        ></h1>
        <h2
            *otbHideInEnv="['hzd-internal']"
            [innerHTML]="
                'dynamische_frontend_texte.persoenliche_daten.html_content.einfuehrung' | translate | safeStyle
            "
        ></h2>
    </div>
</div>
@if (personalDataFields.form_id !== 0) {
    <otb-dynamic-form [personalDataFields]="personalDataFields"></otb-dynamic-form>
}
