<div class="row">
    @if (isLandingPage) {
        <div class="col-12">
            <p class="addition">
                <span
                    [innerHTML]="'dynamische_frontend_texte.landing.html_content.ueberschrift' | translate | safeStyle"
                ></span>
            </p>
        </div>
    }

    <otb-inner-breadcrumb *otbShowInEnv="['default', 'sh', 'education-check']"></otb-inner-breadcrumb>
    @if (!isConfirmationPage && !isCancelPage) {
        <otb-progress-bar></otb-progress-bar>
    }
</div>
