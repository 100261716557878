import { Injectable, OnDestroy } from '@angular/core';
import { AvailableTimesResponse } from '../../api/models/available-times-response';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { AppointmentCalendarService } from '../../api/services/appointment-calendar.service';
import { AvailableTime } from '../../api/models/available-time';
import { AvailableDatesResponse } from '../../api/models/available-dates-response';
import { AvailableDate } from '../../api/models/available-date';
import { BookingService } from '../booking/booking.service';
import { AssignmentStrategyDataBuilderService } from '../assignment-strategy/assignment-strategy-data-builder.service';
import { RequestBodyDates, RequestBodyTimes } from './RequestBodyInterface';
import { LanguageISOType } from '../../types/LanguageISOType';
import { AvailableCourtTime } from '../../api/models/available-court-time';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from '../session-storage/session-storage.service';

@Injectable({
    providedIn: 'root'
})
export class CalendarService implements OnDestroy {
    subs: Subscription = new Subscription();
    selectedDate: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
    availableTimes: BehaviorSubject<Array<AvailableCourtTime>> = new BehaviorSubject<Array<AvailableCourtTime>>([]);
    availableDates: Subject<Array<AvailableDate>> = new Subject<Array<AvailableDate>>();

    defaultDate: Date = new Date();

    constructor(
        private transService: TranslateService,
        private appointmentCalendarService: AppointmentCalendarService,
        private bookingService: BookingService,
        private assignmentStrategyDataBuilderService: AssignmentStrategyDataBuilderService,
        private sessionStorageService: SessionStorageService
    ) {}

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    getAvailableDates(time?: AvailableTime): Observable<AvailableDatesResponse> {
        const isServiceOnly = this.sessionStorageService.loadApplicationSettings()['serviceOnly'];
        if (!isServiceOnly && !this.bookingService.booking.agency) {
            throw new Error('Agency not set');
        }

        if (!this.bookingService.booking.service) {
            throw new Error('Service not set');
        }

        const requestBody: RequestBodyDates = {
            participants_count: this.bookingService.booking.participantsCount,
            agency_id: this.bookingService.booking.agency?.id.toString() ?? '',
            service_id: this.bookingService.booking.service.id.toString(),
            time_choice: time?.time,
            _locale: this.transService.currentLang as LanguageISOType
        };

        this.assignmentStrategyDataBuilderService.addStrategyFieldData(requestBody);

        if (typeof this.bookingService.booking.uuid !== undefined) {
            requestBody.reserved_uuid = this.bookingService.booking.uuid;
        }

        return this.appointmentCalendarService.getApiPublicAvailableDates(requestBody);
    }

    getAvailableTimes(): void {
        this.subs.add(
            this.getAvailableTimesWithoutDate().subscribe((result: AvailableTimesResponse) => {
                this.availableTimes.next(result.data);
            })
        );
    }

    public getAvailableTimesWithoutDate(): Observable<AvailableTimesResponse> {
        const requestBody = this.buildAvailableTimesRequestBody();
        return this.appointmentCalendarService.getApiPublicCalendarTime(requestBody);
    }

    private buildAvailableTimesRequestBody(isServiceOnly: boolean = false): RequestBodyTimes {
        let agencyId = '';
        if (!isServiceOnly) {
            agencyId = this.bookingService.booking.agency?.id.toString() ?? '';
        }
        const requestBody: RequestBodyTimes = {
            participants_count: this.bookingService.booking.participantsCount,
            agency_id: agencyId,
            service_id: this.bookingService.booking.service?.id.toString() ?? '',
            _locale: this.transService.currentLang as LanguageISOType
        };
        this.assignmentStrategyDataBuilderService.addStrategyFieldData(requestBody);
        if (typeof this.bookingService.booking.uuid !== undefined) {
            requestBody.reserved_uuid = this.bookingService.booking.uuid;
        }
        return requestBody;
    }

    public getAvailableTimesWithDate(
        selectedDate: Date,
        isServiceOnly: boolean = false
    ): Observable<AvailableTimesResponse> {
        const requestBody = this.buildAvailableTimesBodyWithDate(selectedDate, isServiceOnly);
        return this.appointmentCalendarService.getApiPublicCalendarTime(requestBody);
    }

    private buildAvailableTimesBodyWithDate(selectedDate: Date, isServiceOnly: boolean): RequestBodyTimes {
        const requestBody = this.buildAvailableTimesRequestBody(isServiceOnly);
        if (selectedDate !== undefined) {
            requestBody.date_choice = selectedDate.toISOString().split('T')[0];
        }
        return requestBody;
    }
}
