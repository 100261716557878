@if (appointmentCancellation !== null && !success && !error) {
    <div class="row">
        <h2
            class="col-12 conf"
            [innerHTML]="'dynamische_frontend_texte.stornierung.html_content.ueberschrift' | translate | safeStyle"
        ></h2>
        <div class="col-12">
            <p>
                <b
                    [innerHTML]="
                        'dynamische_frontend_texte.stornierung.html_content.text_einleitung' | translate | safeStyle
                    "
                ></b>
            </p>
            <p
                [innerHTML]="
                    'dynamische_frontend_texte.stornierung.html_content.dienststelle_uebersicht'
                        | translate
                        | replace: { dienststelle: appointmentCancellation.court }
                "
            ></p>
            <p
                [innerHTML]="
                    'dynamische_frontend_texte.stornierung.html_content.dienstleistung_uebersicht'
                        | translate
                        | replace: { dienstleistung: appointmentCancellation.service }
                "
            ></p>
            <p
                [innerHTML]="
                    'dynamische_frontend_texte.stornierung.html_content.datum_uebersicht'
                        | translate
                        | replace
                            : {
                                  datum: appointmentCancellation.date,
                                  uhrzeit: appointmentCancellation.start_time
                              }
                "
            ></p>
            @if (appointmentCancellation.last_name) {
                <p
                    [innerHTML]="
                        'dynamische_frontend_texte.stornierung.html_content.termin_detail'
                            | translate
                            | replace
                                : {
                                      eintrag_schluessel: 'Nachname',
                                      eintrag_wert: appointmentCancellation.last_name
                                  }
                    "
                ></p>
            }
            @if (appointmentCancellation.first_name) {
                <p
                    [innerHTML]="
                        'dynamische_frontend_texte.stornierung.html_content.termin_detail'
                            | translate
                            | replace
                                : {
                                      eintrag_schluessel: 'Vorname',
                                      eintrag_wert: appointmentCancellation.first_name
                                  }
                    "
                ></p>
            }
            @if (appointmentCancellation.email_address) {
                <p
                    [innerHTML]="
                        'dynamische_frontend_texte.stornierung.html_content.termin_detail'
                            | translate
                            | replace
                                : {
                                      eintrag_schluessel: 'E-Mail-Adresse',
                                      eintrag_wert: appointmentCancellation.email_address
                                  }
                    "
                ></p>
            }
        </div>
        <div class="col-12">
            <button
                (click)="confirmCancel()"
                [innerHTML]="'dynamische_frontend_texte.stornierung.html_content.button_text' | translate | safeStyle"
                id="appointment_cancellation_submit"
                class="btn-success btn"
                btnPrimaryColorDirective
            ></button>
        </div>
    </div>
}

@if (appointmentCancellation === null) {
    <div class="row">
        <h2
            class="col-12 conf"
            [innerHTML]="'dynamische_frontend_texte.stornierung.html_content.ueberschrift' | translate | safeStyle"
        ></h2>
        <p
            class="col-12 conf"
            [innerHTML]="
                'dynamische_frontend_texte.stornierung.html_content.text_nicht_erfolgreich' | translate | safeStyle
            "
        ></p>
    </div>
}

@if (success === true) {
    <div class="row">
        <h2
            class="col-12 conf"
            [innerHTML]="'dynamische_frontend_texte.stornierung.html_content.ueberschrift' | translate | safeStyle"
        ></h2>
        <p
            class="col-12 conf"
            [innerHTML]="'dynamische_frontend_texte.stornierung.html_content.text_erfolgreich' | translate | safeStyle"
        ></p>
    </div>
}

@if (error === true) {
    <div class="row">
        <h2
            class="col-12 conf"
            [innerHTML]="'dynamische_frontend_texte.stornierung.html_content.ueberschrift' | translate | safeStyle"
        ></h2>
        <p class="col-12 conf">
            Leider konnte ihr Termin nicht storniert werden. Bitte wenden Sie sich an ihr
            <span [innerHTML]="'general.service_provider_singular' | translate | safeStyle"></span>.
        </p>
    </div>
}
