import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { SummaryService } from '../../services/summary/summary.service';
import { Booking } from '../../model/booking';
import { StateService } from '../../services/state/state.service';
import { FormField } from '../../api/models/form-field';
import { CustomNgbDateParserFormatter } from '../personal-data/dynamic-form/form-field/fields/date-field/custom-ngbDateParserFormatter';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { BookingService } from '../../services/booking/booking.service';
import { environment } from '../../../environments/environment';
import { LoadingScreenService } from '../../services/loading-screen/loading-screen.service';
import { Subscription, take } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'otb-summary',
    templateUrl: './summary.component.html',
    styleUrls: [
        './summary.component.scss',
        './summary.component.mags-sop.scss',
        './summary.component.finance.scss',
        './summary.component.justice.scss',
        './summary.component.hmdj.scss'
    ]
})
export class SummaryComponent implements OnInit, OnDestroy {
    termsControl: FormControl = new FormControl(false, [Validators.requiredTrue]);
    emailConsentControl: FormControl = new FormControl(false, [Validators.requiredTrue]);
    mustAcceptTerms: boolean = false;
    mustAcceptEmailConsent: boolean = false;
    showTermsError: boolean = false;
    showEmailConsentError: boolean = false;
    formLoading: boolean = false;
    booking: Booking;
    subForms: number[];
    subscription: Subscription = new Subscription();

    constructor(
        private summaryService: SummaryService,
        private bookingService: BookingService,
        private stateService: StateService,
        private loadingScreenService: LoadingScreenService
    ) {}

    ngOnInit(): void {
        this.booking = this.bookingService.booking;

        const stateUpdatedSubscription = this.stateService.stateUpdated$
            .pipe(
                filter((updated) => updated),
                take(1)
            )
            .subscribe(() => {
                const state = this.stateService.getCurrentState();
                this.mustAcceptTerms = state.context.mustAcceptTerms;
                this.mustAcceptEmailConsent = state.context.mustAcceptEmailConsent;
            });
        this.subscription.add(stateUpdatedSubscription);
        this.subForms = Array.from(Array(this.bookingService.booking.participantsCount - 1).keys());

        const loadingScreenSubscription = this.loadingScreenService.loading.subscribe((loading: boolean) => {
            this.formLoading = loading;
        });
        this.subscription.add(loadingScreenSubscription);
    }

    back(): void {
        this.summaryService.abortBooking();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    submit(): void {
        let hasError = false;
        this.showTermsError = false;
        this.showEmailConsentError = false;

        if (this.formLoading) {
            return;
        }

        if (this.mustAcceptTerms && !this.termsControl.valid) {
            this.showTermsError = true;
            hasError = true;
        }

        if (this.mustAcceptEmailConsent && !this.emailConsentControl.valid) {
            this.showEmailConsentError = true;
            hasError = true;
        }

        if (hasError) {
            return;
        }
        this.summaryService.confirmBooking();
    }

    getFormFieldsOrdered(): FormField[] {
        const formFields = this.bookingService.booking.personalDataForm.form_fields;
        formFields.sort((a, b) => {
            return this.sortBySectionAndPosition(a, b);
        });

        return formFields;
    }

    private sortBySectionAndPosition(a: FormField, b: FormField): number {
        const a1 = a.section_in_form === 'section 1' ? 0 : 1;
        const a2 = a.position_in_section;

        const b1 = b.section_in_form === 'section 1' ? 0 : 1;
        const b2 = b.position_in_section;

        return a1 - b1 || a2 - b2;
    }

    getSubFormFieldsOrdered(): FormField[] {
        const formFields = this.bookingService.booking.personalDataForm.sub_form_fields;
        if (formFields !== undefined) {
            formFields.sort((a, b) => {
                return this.sortBySectionAndPosition(a, b);
            });

            return formFields;
        } else {
            return [];
        }
    }

    get formFieldTitles(): string[] {
        return this.getFormFieldsOrdered().map((field) => field.label);
    }

    get subFormFieldTitles(): string[] {
        return this.getSubFormFieldsOrdered().map((field) => field.label);
    }

    get formFieldValues(): string[] {
        const values: string[] = [];
        const parser = new CustomNgbDateParserFormatter();

        this.getFormFieldsOrdered().forEach((field) => {
            if (field.name === 'subForms') {
                return;
            }
            const value = this.bookingService.booking.personalDataValues[field.name];

            if (value === '' || value === null || value === undefined) {
                values.push('');
            } else if (field.type === 'date') {
                values.push(parser.format(value as NgbDate));
            } else if (Array.isArray(value)) {
                values.push(value.join(', '));
            } else {
                values.push(value as string);
            }
        });

        return values;
    }

    get subFormFieldValues(): any[] {
        const vals: any[] = [];
        this.subForms.forEach((formNumber) => {
            const values: string[] = [];
            const parser = new CustomNgbDateParserFormatter();

            this.getSubFormFieldsOrdered().forEach((field) => {
                const value = this.bookingService.booking.personalDataValues['subForms'][formNumber][field.name];

                if (value === '' || value === null || value === undefined) {
                    values.push('');
                } else if (field.type === 'date') {
                    values.push(parser.format(value as NgbDate));
                } else if (Array.isArray(value)) {
                    values.push(value.join(', '));
                } else {
                    values.push(value as string);
                }
            });
            vals.push(values);
        });
        return vals;
    }

    get email(): string {
        return this.bookingService.booking.personalDataValues['email'];
    }

    notMags(): boolean {
        return environment.deploy_environment !== 'mags-sop';
    }
}
