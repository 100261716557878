import { Injectable } from '@angular/core';
import { EnvironmentsEnum } from '../../enum/environments-enum';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EnvironmentCssClassesGeneratorService {
    constructor() {}

    get serviceNoticeClasses(): any {
        const smallWidthRightSide: string[] = [
            EnvironmentsEnum.JUSTICE,
            EnvironmentsEnum.DEFAULT,
            EnvironmentsEnum.SH,
            EnvironmentsEnum.FINANCE,
            EnvironmentsEnum.HMDJ,
            EnvironmentsEnum.HMDIS_INTERNAL,
            EnvironmentsEnum.BB_JM
        ];
        const smallWidthLeftSide: string[] = [EnvironmentsEnum.EDUCATION_CHECK];
        const classList = {
            'col-12': false,
            'col-sm-6': false,
            'offset-0': false,
            'offset-sm-6': false
        };

        if (smallWidthRightSide.includes(environment.deploy_environment)) {
            classList['col-12'] = true;
            classList['col-sm-6'] = true;
            classList['offset-0'] = true;
            classList['offset-sm-6'] = true;
        } else {
            classList['col-12'] = true;
        }
        if (smallWidthLeftSide.includes(environment.deploy_environment)) {
            classList['col-12'] = true;
            classList['col-sm-6'] = true;
            classList['offset-0'] = true;
        } else {
            classList['col-12'] = true;
        }

        return classList;
    }
}
