<div class="main-wrapper">
    <!-- MAGS SOP -->
    <otb-top-breadcrumb #breadcrumbs *otbShowInEnv="['mags-sop']"></otb-top-breadcrumb>
    @if (!headlessMode) {
        <otb-header></otb-header>
    }
    <!-- true -> workaround for mags -->
    <div class="container" style="{{ breadcrumbsHeightMargin }}">
        <main>
            <otb-warning></otb-warning>
            @if ((applicationSettingsLoaded | async) && !errorLoadingApplicationSettings()) {
                <router-outlet></router-outlet>
            }
            @if ((applicationSettingsLoaded | async) === false && !errorLoadingApplicationSettings()) {
                <otb-skeleton-loader-main></otb-skeleton-loader-main>
            }
        </main>
    </div>

    @if (errorLoadingApplicationSettings()) {
        <div class="container">
            <div class="col-12">
                <h3
                    [innerHTML]="'dynamische_frontend_texte.error_message.application_settings' | translate | safeStyle"
                ></h3>
            </div>
        </div>
    }
</div>

<!-- Footer -->
@switch (env) {
    <!-- Footer SH -->
    @case ('sh') {
        <otb-footer-sh></otb-footer-sh>
    }
    @default {
        @if (!headlessMode) {
            <otb-footer></otb-footer>
        }
    }
}

<div class="d-none">{{ theme }}</div>
